import React from "react";
import "./index.css";

const History = () => {
  return (
    <div id="history" className="his_container">
      <h1>Know about spearfishing and hunting</h1>
      <hr />
      <p>
        Spearfishing is a method of fishing that involves impaling the fish with
        a straight pointed object such as a spear, gig or harpoon. It has been
        deployed in artisanal fishing throughout the world for millennia. Early
        civilisations were familiar with the custom of spearing fish from rivers
        and streams using sharpened sticks. Modern spearfishing usually involves
        the use of underwater swimming gear and slingshot-like elastic powered
        spearguns or compressed gas powered pneumatic spearguns, which launch a
        tethered underwater projectile to strike the target fish. Specialised
        techniques and equipment have been developed for various types of
        aquatic environments and target fish. Spearfishing may be done using
        free-diving, snorkelling or scuba diving techniques, but spearfishing
        while using scuba equipment is illegal in some countries. The use of
        mechanically powered spearguns is also outlawed in some countries and
        jurisdictions such as New Zealand. Spearfishing uses no bait and is
        highly selective, with no by-catch.
      </p>

      <h2>History</h2>
      <p>
        Spearfishing with barbed poles (harpoons) was widespread in palaeolithic
        tim Cosquer Cave in Southern France contains cave art over 16,000 years
        old, including drawings of seals which appear to have been
        harpooned.[citation needed] There are references to fishing with spears
        in ancient literature; though, in most cases, the descriptions do not go
        into detail. An early example from the Bible is in Job 41:7: Canst thou
        fill his [Leviathan] skin with barbed irons? or his head with fish
        spears?. The Greek historian Polybius (ca 203 BC–120 BC), in his
        Histories, describes hunting for swordfish by using a harpoon with a
        barbed and detachable head. Greek author Oppian of Corycus wrote a major
        treatise on sea fishing, the Halieulica or Halieutika, composed between
        177 and 180. This is the earliest such work to have survived intact.
        Oppian describes various means of fishing including the use of spears
        and tridents. In a parody of fishing, a type of gladiator called
        retiarius carried a trident and a casting-net. He fought the murmillo,
        who carried a short sword and a helmet with the image of a fish on the
        front. Copper harpoons were known to the seafaring Harappans well into
        antiquity. Early hunters in India include the Mincopie people,
        aboriginal inhabitants of India's Andaman and Nicobar islands, who have
        used harpoons with long cords for fishing since early times.
      </p>
      <h2>Traditional</h2>
      <p>
        Spear fishing is an ancient method of fishing and may be conducted with
        an ordinary spear or a specialised variant such as an e or the trident.
        A small trident-type spear with a long handle is used in the American
        South and Midwest for gigging bullfrogs with a bright light at night, or
        for gigging carp and other fish in the shallows.
      </p>
      <h2>Modern</h2>
      <p>
        Traditional spear fishing is restricted to shallow waters, but the
        development of the speargun, diving mask and swimfins allows fishing in
        deeper waters. With practice, some freedivers are able to hold their
        breath for up to ten minua diver with underwater breathing equipment can
        dive for much longer periods. In the 1920s, sport spearfishing using
        only watertight swimming goggles became popular on the Mediterranean
        coast of France and Italy. This led to development of the modern diving
        mask, fins and snorkel. Modern scuba diving had its genesis in the
        systematic use of rebreathers by Italian sport spearfishers during the
        1930s. This practice came to the attention of the Italian Navy, which
        developed its frogman unit, which affected World War By 1940 small
        groups of people in California, USA had been spearfishing for less than
        10 years. Most used imported gear from Europe, while innovators Charlie
        Sturgill, Jack Prodanovich, and Wally P invented and built innovative
        equipment for California's div During the 1960s, attempts to have
        spearfishing recognised as an Olympic sport were unsuccessful. Instead,
        two organisations, the International Underwater Spearfishing Association
        (IUSA) and the International Bluewater Spearfishing Records Committee
        (IBSRC), list world record catches by species according to rules to
        ensure fair competition. Spearfishing is illegal in many bodies of
        water, and some locations only allow spearfishing during certain
        seasons.
      </p>
      <h2>Conservation</h2>
      <p>
        Spearfishing has been implicated in local disappearances of some
        species, including the Atlantic goliath grouper on the Caribbean island
        of Bonaire, the Nassau grouper in the barrier reef off the coast of
        Belize and the giant black sea bass in California, which have all been
        listed as endangered[citation needed]. Modern spearfishing has shifted
        focus onto catching only what one needs and targeting sustainable
        fisheries. As gear evolved in the 1960s and 1970s spearfishermen
        typically viewed the ocean as an unlimited resource and often sold their
        catch. This practice is now heavily frowned upon in prominent
        spearfishing nations for promoting unsustainable methods and encouraging
        taking more fish than is needed. In countries such as Australia and
        South Africa where the activity is regulated by state fisheries,
        spearfishing has been found to be the most environmentally friendly form
        of fishing due to being highly selective, having no by-catch, causing no
        habitat damage, nor creating pollution or harm to protected endangered
        species. In 2007, the Australian Bluewater Freediving Classic became the
        first spearfishing tournament to be accredited and was awarded 4 out of
        5 stars based on environmental, social, safety and economic indicators.
      </p>
      <h2>Blue water hunting</h2>
      <p>
        Blue water hunting involves diving in open ocean waters for pelagic
        species. It involves accessing usually very deep and clear water and
        chumming for large pelagic fish species such as marlin, tuna, wahoo, or
        giant trevally. Blue water hunting is often conducted in drifts; the
        boat driver drops divers and allow them to drift in the current for up
        to several kilometres before collecting them. Blue water hunters can go
        for hours without seeing any fish, and without any ocean structure or a
        visible bottom the divers can experience sensory deprivation and have
        difficulty determining the size of a solitary fish. One technique to
        overcome this is to note the size of the fish's eye in relation to its
        body. Large specimens have a proportionally smaller eye. The creation of
        the Australian Bluewater Freediving Classic in 1995 in northern New
        South Wales was a way of creating interest and promotion of this format
        of underwater hunting, and contributed to the formation of the
        International Bluewater Spearfishing Records Committee. The IBSRC formed
        in 1996, was the first dedicated organization worldwide, created by
        recognized world leaders in blue-water hunting, to record the capture of
        pelagic species by blue-water hunters. The Blue Water World Cup in La
        Ventana, BCS, Mexico has also brought a large amount of notoriety to the
        sport. Started in 2006 by Dennis Haussler. Elite spear fishermen from
        all over the world compete in a 4 day format that involves very
        selective spearing of pelagic species, with Wahoo, AmberJack, Dorado,
        Roosterfish, Marlin and Tuna being the target species. The diving is
        dynamic and challenging with depths that vary from 15 ft to over 100 ft.
        Notably, some blue water hunters use large multi-band wooden guns and
        make use of breakaway rigs to catch and subdue their prey. If the prey
        is large and still has fight left after being subdued, a second gun can
        provide a kill shot at a safe distance. This is acceptable to IBSRC and
        IUSA regulations as long as the spearo loads it himself in the water.
        Blue water hunting is conducted worldwide, but notable hot spots include
        Baja Mexico (yellowfin tuna, wahoo), Southern California (bluefin tuna),
        Tanzania (dogtooth tuna, wahoo and yellowfin tuna), Mozambique (dogtooth
        tuna, wahoo and giant turrum), South Africa (Yellowfin tuna, Spanish
        Mackerel, wahoo, marlin and giant turrum), Australia (dogtooth tuna,
        wahoo and Spanish Mackerel) and the South Pacific (dogtooth tuna).
      </p>
      <h2>Freshwater hunting</h2>
      <p>
        Many US states allow spearfishing in lakes and rivers, but nearly all of
        them restrict divers to shooting only rough fish such as carp, gar,
        bullheads, suckers, etc. A few US states do allow the taking of certain
        gamefish such as sunfish, crappies, striped bass, catfish and walleyes.
        Freshwater hunters typically have to deal with widely varying seasonal
        changes in water clarity due to flooding, algae blooms and lake
        turnover. Some especially hardy midwestern and north central scuba
        divers go spearfishing under the ice in the winter when water clarity is
        at its best. In the summer the majority of freshwater spearfishermen use
        snorkelling gear rather than scuba since many of the fish they pursue
        are in relatively shallow water. Carp shot by freshwater spear fishermen
        typically end up being used as fertilizer, bait for trappers, or are
        occasionally donated to zoos.
      </p>
    </div>
  );
};

export default History;
