export const EquipmentData = [
  {
    id: 1,
    img: 'https://tse3.mm.bing.net/th?id=OIP.U_o4xXLZGy6csnMbTXi5IwHaHa&pid=Api&P=0',
    name: 'Speargun',
    disc: 'A speargun is an underwater fishing implement designed to fire a spear at fish. The most popular spearguns are powered by natural latex rubber bands, while pneumatic powered guns are also used, but less powerful.',
  },
  {
    id: 2,
    img: 'https://cdn.shopify.com/s/files/1/0229/6909/products/headhunter-spearfishing-nomad-roller-three-piece_1200x1200.jpg?v=1617976061',
    name: 'Polespear',
    disc: 'Pole spears, or hand spears, consist of a long shaft with point at one end and an elastic loop at the other for propulsion. They also come in a wide variety, from aluminum or titanium metal, to fiberglass or carbon fiber. ',
  },
  {
    id: 3,
    img: 'https://i.ebayimg.com/images/g/SxEAAOSwhJNf7BJG/s-l400.jpg',
    name: 'Hawaiian slings',
    disc: 'Hawaiian slings consist of an elastic band attached to a tube, through which a spear is launched.',
  },
  {
    id: 4,
    img: 'https://marine-deals.freetls.fastly.net/media/catalog/product/cache/1/image/1b2e871699de66caea4208e2cf16bd73/1/2/124164_1_pc_y.jpg',
    name: 'Wet suit',
    disc: "Wetsuits designed specifically for spearfishing are often two-piece (jacket and high waisted pants or 'long-john' style pants with shoulder straps) and are black or are fully or partially camouflage.",
  },
  {
    id: 5,

    img: 'https://pull02-diverssupply.netdna-ssl.com/media/catalog/product/cache/f31ebda34be74fb90fbee470907321b4/image/37783cc99/xs-scuba-weight-belt.jpg',
    name: 'Weight belt',
    disc: 'These are used to compensate for wetsuit buoyancy and help the diver descend to depth. Rubber belts which can be quickly released in an emergency have proven to be particularly popular for spearfishing worldwide.',
  },
  {
    id: 6,
    img: 'https://s3-us-west-2.amazonaws.com/imgds360live/product_images/2958042/thumb/xl_1517435468.jpeg',
    name: 'Fins',
    disc: 'Fins for freedive spearfishing are much longer than those used in scuba to aid in fast ascent. Typically a closed foot design is used by freediving and snorkelling.',
  },
  {
    id: 7,
    img: 'https://i.pinimg.com/736x/3f/cd/37/3fcd37ebc0c5579b601a72c29fb790e0.jpg',
    name: 'Knife',
    disc: 'A knife is carried as a safety precaution in case the diver becomes tangled in a spearline or floatline. It can also be used as an ikejime or kill spike.',
  },
  {
    id: 8,
    img: 'https://images-na.ssl-images-amazon.com/images/I/41R6DNAArTL._SY300_.jpg',
    name: 'Kill spike',
    disc: 'In lieu of a knife, a sharpened metal spike can be used to kill the fish quickly and humanely upon capture. This action reduces interest from sharks by stopping the fish from thrashing.',
  },
  {
    id: 9,
    img: 'https://www.aquam.com/product/image/large/dv-82107_1.jpg',
    name: 'Buoy',
    disc: "A buoy is usually tethered to the spearfisher's speargun or directly to the spear. A buoy helps to subdue large fish.",
  },
  {
    id: 10,
    img: 'https://www.spearfishingworld.com/product_images/t/256/SEAVINE_FLOATLINE__65412_zoom.jpg',
    name: 'Floatline',
    disc: 'A floatline connects the buoy to the speargun or to the weight-belt. Often made from braided polyester.',
  },
  {
    id: 11,
    img: 'https://i5.walmartimages.com/asr/fce0fe7e-bc98-4363-bd2f-a7e26cbf8e0a_1.075fd767d941d99484b332cfa5ed9306.jpeg',
    name: 'Gloves',
    disc: 'Gloves protect the hands when retrieving fish from coral or rock crevices, when loading the bands on rubber powered spearguns and from the teeth and spines of struggling fish.',
  },
  {
    id: 12,
    img: 'https://ae01.alicdn.com/kf/HTB1xr3nubwrBKNjSZPcq6xpapXam/Lixada-Kids-Scuba-Diving-Mask-Tube-Set-Snorkeling-Mask-Goggles-Glasses-Easy-Breath-Dry-Snorkel-Swimming.jpg',
    name: "Snorkel & d'mask",
    disc: 'Spearfishing snorkels and diving masks are similar to those used for scuba diving, although the masks usually have two lenses and a lower internal volume.',
  },
  {
    id: 13,
    img: 'https://i5.walmartimages.com/asr/34f3544a-d4d9-4c0b-b528-3def992fd9df_1.73e0eefba335f86552726c6cc72b92de.jpeg',
    name: 'Diver down flag',
    disc: 'The diver down flag also called a dive flag is a safety flag used on the water to indicate to other boats that there is a diver below. When in use, it signals to other vessels to keep clear.',
  },
];
